import React from 'react';
import { Layout, Col, Button } from 'antd';
import Responsive from '../utils/Responsive';
import styles from '../styles/components.module.css';
import { Link } from 'gatsby';

const Row = Responsive.ResponsiveRow;
const { Content } = Layout;

export default function Blog() {
  return (
    <Content>
      <Row className={styles.blog} style={{ maxWidth: 'none' }}>
        <div className={styles.blogInner}>
          <div>
            <p>AWS Tag Editor, how does it work?</p>
            <span>
              Some tips to understand how the Tag Editor tool works and the
              different features that are offered
            </span>
            <Link to="/tag-editor-article/">Read more...</Link>
          </div>
          <div>
            <p>Tagbot, the simple and smart alternative to manage your tags</p>
            <span>
              What's going on inside Tagbot? How do you use it? A very quick
              guide to explain how to use the easiest tool to manage your tags.
            </span>
            <Link to="/tagbot-article/">Read more...</Link>
          </div>
        </div>
      </Row>
    </Content>
  );
}
